@if (!nav.cookieEnabled) {
  <div>
    <h2>Please enable cookies in order to view this site.</h2>
  </div>
}
@if (nav.cookieEnabled) {
  <div id="universe" class="universe">
    <div class="universe-content">
      <div id="outlet" class="outlet">
        <router-outlet></router-outlet>
        <!-- active component will be rendered here-->

        <!-- Overlays -->
        <pc-status-overlay></pc-status-overlay>
        <app-multi-status-overlay></app-multi-status-overlay>
      </div>
    </div>
    <!-- end of content -->
  </div>
}
<!-- end of universe -->

<!-- maintenence  -->
@if (
  (app.environment.frontend === 'production' && app.content.content.maintenance?.fields.maintenanceModeProduction) ||
  (app.environment.frontend === 'dev' && app.content.content.maintenance?.fields.maintenanceModeDevelopment) ||
  (app.environment.frontend === 'staging' && app.content.content.maintenance?.fields.maintenanceModeStaging)
) {
  <flex-col-center-center style="background: white; position: fixed; left: 0; top: 0; width: 100vw; height: 100vh; z-index: 4">
    <app-public-header></app-public-header>

    <flex-col-center-center style="width: 100%; max-width: 980px; padding: 40px; box-sizing: border-box">
      <h1 style="font-family: source-sans; font-weight: 500">
        {{ 'APP.MAINTENANCE_MESSAGE' | translate }}
      </h1>
      <div class="markdown" [innerHtml]="app.content.content.maintenance?.fields.maintenanceReason | markdown" style="font-size: 24px"></div>

      <pc-button
        [text]="'APP.MAINTENANCE_ANNOUNCEMENTS' | translate"
        (btnClick)="ui.openURL(app.content.content.maintenance?.fields.announcementLink)"></pc-button>
    </flex-col-center-center>
  </flex-col-center-center>
}
